import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et">
      <h2>Kampaania “ROHKEM KODUSEID HETKI” reeglid</h2>
      <ol>
        <li>
          Kampaania “<span className="bold">ROHKEM KODUSAID HETKI</span>” on
          alates <span className="bold">01.02.2023</span> kuni{' '}
          <span className="bold">28.02.2023</span> toimuv tarbijamäng (edaspidi
          kampaania), mida korraldab Fazer Eesti OÜ, (asukoht: Pärnu maantee
          158/1, Tallinn; edaspidi korraldaja).
        </li>
        <li>
          Kampaania <span className="bold">auhinnafondis</span> on{' '}
          <span className="bold">KitchenAid espressomasin Artisan</span> (kokku
          1 tk väärtusega 499€),{' '}
          <span className="bold">KitchenAid kohvijahvati</span> (kokku 2 tk,
          väärtusega 249€),{' '}
          <span className="bold">KitchenAid veekeetja Stella</span> (kokku 3 tk,
          väärtusega 109€),{' '}
          <span className="bold">KitchenAid juhtmevaba käsimikser</span> (kokku
          3 tk, väärtusega 159€), mis loositakse välja 03.03.2023. Auhinnafondi
          koguväärtus on 1801€.
        </li>
        <li>
          Kampaania läbiviimise kord on kehtestatud korraldaja poolt
          käesolevates reeglites. Kõik korraldaja otsused kampaania läbiviimisel
          on lõplikud ning kõigile kampaanias osalejatele kohustuslikud.
        </li>
        <li>
          Kampaaniatooted on{' '}
          <span className="bold">
            Kodusai suur 500g, Kodusai 300g, Kodusai Röst 500g, Kodusai Mini
            Röst 240g, Kodusai Mitmevilja Röst 450g, Kodusai Minisaiake
            karamelliga 220g, Kodusai Minisaiake kaneeliga 220g, Kodusai
            Šokolaadi-kaneelisaiake 130g, Kodusai Kohupiimasaiake 130g.
            (edaspidi tooted).
          </span>
        </li>
        <li>
          Kampaanias osalemiseks tuleb osta vähemalt üks Kodusai toode ja
          registreerida ostutšekk interneti kaudu kampaanialehel{' '}
          <a href="https://kodusai.messenger.ee">
            https://kodusai.messenger.ee{' '}
          </a>{' '}
          Kampaanias osalemine on tasuta. Auhinna kättesaamiseks tuleb kampaania
          korraldajale esitada ostutšekk.{' '}
          <ul>
            <li>
              Loosimises osalemiseks võib ühe ostutšeki numbri esitada ainult
              ühe korra. Sama ostutšeki mitmekordsel registreerimisel osaleb
              ainult esimesena saabunud registreering.
            </li>
            <li className="bold">Isikuandmete töötlemise kord</li>
            <li>
              Fazer Eesti OÜ töötleb kampaania käigus osaleja poolt esitatud
              isikuandmeid vastavalt Eesti Vabariigis kehtivatele seadustele
              kampaania läbiviimise eesmärgil.
            </li>
            <li>
              Kampaanias osaleja annab Fazer Eesti OÜ-le nõusoleku töödelda
              esitatud andmeid järgnevatel eesmärkidel:
            </li>
            <li>kampaania tulemuste kohta kokkuvõtete tegemiseks;</li>
            <li>kampaaniaga seotud koostööpartneritele info edastamiseks;</li>
            <li>võitjate välja loosimiseks ja nende teavitamiseks;</li>
            <li>võitjate nimede avaldamiseks kampaania veebilehel.</li>
            <li>
              Kampaanias osalejatel on õigus saada lisainfot Fazer Eesti OÜ
              poolt isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri
              aadressile: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
            </li>
            <li>
              Isikuandmete vastutav töötleja on Fazer Eesti OÜ, registrikood
              10057691, Pärnu maantee 158/1, Tallinn 11317; tel 6502421;{' '}
              <a href="mailto:info@fazer.ee">info@fazer.ee</a>
            </li>
            <li>
              Kogutud andmeid säilitatakse kolm kuud alates kampaania
              lõpukuupäevast ehk kuni 01.07.2023. Võitjate täielik nimekiri on
              veebilehel üleval kuni 31.03.2023.{' '}
            </li>
          </ul>
        </li>
        <li>
          <span className="bold">03.03.2023</span> loositakse kell 12.00 kõikide
          kampaanias osalejate vahel välja{' '}
          <span className="bold">
            1 tk KitchenAid espressomasin Artisan, 2 tk KitchenAid
            kohvijahvatit, 3 tk KitchenAid veekeetjat Stella, 3 tk KitchenAid
            juhtmevaba käsimikserit.
          </span>{' '}
          <ul>
            <li>
              Auhindade loosimises osalevad kõik veebis registreerunud isikud
              kampaania reeglites esitatud perioodil (alates keskkonna
              avanemisest). Võitjaid informeeritakse telefoni või meili teel.
              Loosimise juures viibib vähemalt üks Fazer Eesti OÜ esindaja.
            </li>
          </ul>
        </li>
        <li>
          Auhindade võitjate nimed avaldatakse internetis aadressil hiljemalt
          loosimisele järgneval kolmandal tööpäeval. Võitjatega võetakse
          korraldaja esindaja poolt ühendust andmete ja auhinna
          kättetoimetamisviisi täpsustamiseks.
        </li>
        <li>
          Juhul, kui auhinna võitja ei vasta korraldaja poolt kehtestatud
          kampaania tingimustele või kui ta ei ole alles hoidnud toote
          ostutšekki, millel oleva numbri ta edastas kampaania korraldajale
          veebi vahendusel; samuti juhul, kui auhinna võitja ei ole viie
          tööpäeva jooksul alates, tema, kui auhinna võitja nime avalikustamist
          internetis aadressil{' '}
          <a href="https://kodusai.messenger.ee">
            https://kodusai.messenger.ee
          </a>
          , olnud veebis esitatud kontaktandmetel kättesaadav andmete
          täpsustamiseks, on korraldajal õigus jätta talle auhind üle andmata.
          Välja andmata jäänud auhind loositakse uuesti välja.
        </li>
        <li>
          Korraldajalt ükskõik missuguse kampaania auhinna saaja annab loa
          korraldajale kasutada võitja nime meedias, reklaamides,
          PR-kampaaniates jms üritustel ilma võitjale selle eest täiendavat tasu
          maksmata ja vastavat kokkulepet sõlmimata.{' '}
        </li>
        <li>
          Kampaania käigus kampaanias osalejate poolt võidetud auhindu ei
          asendata võitja nõudmisel teist liiki auhinnaga ega hüvitata võitjale
          auhinna maksumust rahas, v.a juhul, kui auhind asendatakse teise
          samaväärse auhinnaga korraldaja otsusel.{' '}
        </li>
        <li>
          Auhindade võitjatele üleandmisega seotud riiklikud maksud tasub
          korraldaja Eesti Vabariigi seadustes sätestatud korras ja tähtaegadel.{' '}
        </li>
        <li>
          Kampaanias osaleja poolt kampaania tingimuste eiramise või
          korraldajale enda kohta valede või ebatäpsete andmete esitamise
          korral, samuti muude ebaõnnestumiste korral (korraldajal ei õnnestu
          auhinna võitjaga kontakti saada), mis ei ole tingitud korraldaja
          süüst, ei vastuta korraldaja kampaanias osaleja auhindade loosimisest
          või auhinna kätteandmisest kõrvalejäämise eest.{' '}
        </li>
        <li>
          Vääramatu jõu (force majeure) asjaolude ilmnemisel on korraldajal
          ühepoolne õigus katkestada kampaania ja jätta auhinnad välja loosimata
          ja/või auhinnad võitjatele üle andmata, teatades sellest koheselt
          kampaanias osalejatele meedia vahendusel.{' '}
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadusandlusele.
        </li>
        <li>
          Kõik pretensioonid seoses kampaania korraldamise ja/või läbiviimisega
          tuleb saata kirjalikult kampaania korraldaja asukohta: Pärnu maantee
          158/1, 11317 Tallinn märksõnaga “
          <span className="bold">KAMPAANIA</span>" hiljemalt 01.03.2023.
          Kaebused, mis esitatakse peale nimetatud kuupäeva, ei võeta menetluse.
          Laekunud pretensioonidele vastatakse 3 tööpäeva jooksul. Kampaania
          lisainfo telefonil 650 2421;{' '}
          <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
        </li>
      </ol>
    </div>
  );
};

export default Et;
