import React, { ReactElement } from 'react';
import './Rules.scss';
import i18n from 'i18next';

// Partial imports
import Et from './Et';
import { Link } from 'react-router-dom';

const Rules: React.FC = (): ReactElement => {
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  let RuleSet = Et;
  switch (getLanguage()) {
    case 'et':
      RuleSet = Et;
      break;
    default:
      RuleSet = Et;
      break;
  }
  return (
    <div className="Rules">
      <Link to="/" className="close-button">
        &times;
      </Link>
      <RuleSet />
    </div>
  );
};

export default Rules;
