import React, { ReactElement, useEffect, useState } from 'react';
import './Winners.scss';
import _ from 'lodash';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface PrizeList {
  espressomasin: Pick<any, string | number | symbol>[];
  mikser: Pick<any, string | number | symbol>[];
  kohvijahvati: Pick<any, string | number | symbol>[];
  veekeetja: Pick<any, string | number | symbol>[];
}

const Winners: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [winners, setWinners] = useState<PrizeList>({
    espressomasin: [],
    mikser: [],
    kohvijahvati: [],
    veekeetja: [],
  });

  const getWinners = async () => {
    const res = await axios.get('/api/dataservice/winners/');
    // console.log(res);
    const prizes = _.mapValues(_.groupBy(res.data, 'prize'), (plist) =>
      plist.map((prize) => _.omit(prize, 'prize'))
    );

    setWinners({
      espressomasin:
        prizes.espressomasin === undefined ? [] : prizes.espressomasin,
      mikser: prizes.mikser === undefined ? [] : prizes.mikser,
      kohvijahvati:
        prizes.kohvijahvati === undefined ? [] : prizes.kohvijahvati,
      veekeetja: prizes.veekeetja === undefined ? [] : prizes.veekeetja,
    });
  };

  useEffect(() => {
    getWinners();
  }, []);

  // return (
  //   <section id="winners" className="Winners">
  //     <div className="container"></div>
  //   </section>
  // );

  return (
    <section id="winners" className="Winners">
      <div className="container">
        <h2>{t('winners')}</h2>
        <div className="winners-wrapper columns">
          <div className="column">
            <ul className={winners.espressomasin.length === 0 ? 'hidden' : ''}>
              <li className="prize-name">{t('Main prize')}</li>
              {/*winners.espressomasin.map(function (item: any, index: any) {
                return (
                  <li key={index}>{item.first_name + ' ' + item.last_name}</li>
                );
              })*/}
              {/* <li>Test Winner</li> */}
            </ul>
          </div>
          <div className="column">
            <ul className={winners.kohvijahvati.length === 0 ? 'hidden' : ''}>
              <li className="prize-name">{t('Mid prize')}</li>
              {/*winners.kohvijahvati.map(function (item: any, index: any) {
                return (
                  <li key={index}>{item.first_name + ' ' + item.last_name}</li>
                );
              })*/}
              {/* <li>Test Winner</li>
             <li>Test Winner</li>
             <li>Test Winner</li> */}
            </ul>
          </div>
          <div className="column">
            <ul className={winners.veekeetja.length === 0 ? 'hidden' : ''}>
              <li className="prize-name">{t('Mid prize 2')}</li>
              {/*winners.veekeetja.map(function (item: any, index: any) {
                return (
                  <li key={index}>{item.first_name + ' ' + item.last_name}</li>
                );
              })*/}
              {/* <li>Test Winner</li>
             <li>Test Winner</li>
             <li>Test Winner</li> */}
            </ul>
          </div>
          <div className="column">
            <ul className={winners.mikser.length === 0 ? 'hidden' : ''}>
              <li className="prize-name">{t('Weekly prize')}</li>
              {/*winners.mikser.map(function (item: any, index: any) {
                return (
                  <li key={index}>{item.first_name + ' ' + item.last_name}</li>
                );
              })*/}
              {/* <li>Test Winner</li>
             <li>Test Winner</li>
             <li>Test Winner</li>
             <li>Test Winner</li>
             <li>Test Winner</li> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Winners;
