import React, { ReactElement } from 'react';
import './Main.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Register from '../../components/register/Register';
import Winners from '../../components/winners/Winners';

import Prizes from '../../assets/images/prizes.png';
import Logo from '../../assets/images/logo.png';
import Sai1 from '../../assets/images/sai_1.png';
import Sai2 from '../../assets/images/sai_2.png';
import Sai3 from '../../assets/images/sai_3.png';
import Sai4 from '../../assets/images/sai_4.png';
import Sai5 from '../../assets/images/sai_5.png';
import Sai6 from '../../assets/images/sai_6.png';
import Sai7 from '../../assets/images/sai_7.png';
import Sai8 from '../../assets/images/sai_8.png';
import Sai9 from '../../assets/images/sai_9.png';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Main">
      <section className="header">
        <div className="columns language-row">
          <div className="column is-offset-7"></div>
        </div>
        <div className="columns top-row">
          <div className="column is-3 logo-column">
            <img src={Logo} alt="fazer" />
          </div>
          <div className="column is-4 campaign-header-column">
            <h1>{t('main header')}</h1>
            <p>{t('header description.part 1')}</p>
            <Link to="/rules">{t('header description.part 2')}</Link>
            <p>{t('header description.part 3')}</p>
          </div>
          <div className="column">
            <img
              src={Prizes}
              className="prizes-mobile is-hidden-desktop"
              alt="toaster and coffee maker"
            />
          </div>
        </div>
        <div className="columns bottom-row">
          <div className="column"></div>
          <div className="column is-2">
            <ul>
              <li>{t('prize list.first')}</li>
              <li>{t('prize list.second')}</li>
              <li>{t('prize list.third')}</li>
              <li>{t('prize list.fourth')}</li>
            </ul>
          </div>
          <div className="column prize-column">
            <div className="prize-child is-hidden-touch">
              <img
                src={Prizes}
                className="prizes"
                alt="toaster and coffee maker"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="register-form">
        <div className="columns">
          <div className="column is-3"></div>
          <div className="column">
            <Register />
          </div>
          <div className="column is-3"></div>
        </div>
      </section>

      <section className="products">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <div className="columns heading-columns">
              <div className="column is-narrow">
                <h2>{t('participating products')}</h2>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="columns is-centered">
                  <div className="column is-narrow">
                    <img src={Sai1} alt="bread" />
                  </div>
                  <div className="column is-narrow">
                    <img src={Sai2} alt="bread" />
                  </div>
                  <div className="column is-narrow">
                    <img src={Sai3} alt="bread" />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="columns is-centered">
                  <div className="column is-narrow">
                    <img src={Sai4} alt="bread" />
                  </div>
                  <div className="column is-narrow">
                    <img src={Sai5} alt="bread" />
                  </div>
                  <div className="column is-narrow">
                    <img src={Sai6} alt="bread" />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="columns is-centered">
                  <div className="column is-narrow">
                    <img src={Sai7} alt="bread" />
                  </div>
                  <div className="column is-narrow">
                    <img src={Sai8} alt="bread" />
                  </div>
                  <div className="column is-narrow">
                    <img src={Sai9} alt="bread" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Winners />
    </div>
  );
};

export default Main;
